function ErrorPage() {
	return (
		<div>
			<h1>Oops😅</h1>
			<p>An error has occured</p>
		</div>
	)
}

export default ErrorPage
