import './NavigationBar.scss'
import { NavLink } from 'react-router-dom'

type NavigationBarProps = {
	className?: string
}

function NavigationBar({ className }: NavigationBarProps) {
	return (
		<nav className={className}>
			<ul>
				<li>
					<NavLink className="navigation-link" to={`/wishlist`}>
						Wishlist
					</NavLink>
				</li>
				<li>
					<NavLink className="navigation-link" to={'/chat'}>
						Chat
					</NavLink>
				</li>
				<li>
					<NavLink className="navigation-link" to={`/profile`}>
						Profile
					</NavLink>
				</li>
			</ul>
		</nav>
	)
}

export default NavigationBar
