import './ComingSoon.scss'

function ComingSoon() {
	return (
		<div className="coming-soon-container">
			<h1>Coming Soon...</h1>
		</div>
	)
}

export default ComingSoon
