import { FC, ReactNode, useState } from 'react'
import { ReservationContext, ReservedProduct } from './ReservationContext'

type ReservationContextProviderProps = {
	children: ReactNode
}

const ReservationContextProvider: FC<ReservationContextProviderProps> = ({ children }) => {
	const [reservedProduct, setReservedProduct] = useState<ReservedProduct | null>(null)

	return (
		<ReservationContext.Provider value={{ reservedProduct, setReservedProduct }}>
			{children}
		</ReservationContext.Provider>
	)
}

export default ReservationContextProvider
