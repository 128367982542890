import { useEffect, useState } from 'react'
import reservationAPI from '../../api/ReservationAPI'
import { ReservedProduct, useReservationContext } from '../Context/ReservationContext'
import { useAuthContext } from '../Context/AuthContext'
import { Product } from '../../api/ProductAPI'

function useProductCard(
	setReservationModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
	product: Product,
	imageSrc: string
) {
	const [reserved, setReserved] = useState(false)
	const { setReservedProduct } = useReservationContext()
	const { reservedProduct } = useReservationContext()
	const { user } = useAuthContext()

	useEffect(() => {
		const fetchReservation = async () => {
			const response = await reservationAPI.getReservationForProduct(product.id)

			if (response?.status !== 200) {
				console.error('Something went wrong while fetching reservation')
				return
			}

			const { reservation } = response.data

			if (reservation) {
				setReserved(true)
			}
		}
		fetchReservation()
	}, [reservedProduct, product.id])

	const handleClick = async () => {
		if (!reserved) {
			const reservedProduct: ReservedProduct = {
				id: product.id,
				name: product.name,
				imageSrc,
				links: product.links,
				price: product.price,
				notes: product.notes,
			}
			setReservedProduct(reservedProduct)
			setReservationModalOpen(true)
		}
		if (reserved) {
			// Deactivated temporarily
			/* if (!user) {
				console.error('Cannot cancel reservation. User is null')
				return
			}
			const response = await reservationAPI.cancelReservationForProduct(product.id, user?._id)

			if (response?.status !== 200) {
				console.error('An error ocurred while cancel of reservation: ', response?.data.message)
			} else setReserved(false) */
		}
	}
	return { reserved, handleClick }
}

export default useProductCard
