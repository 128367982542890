import React, { useState, useEffect } from 'react'
import { CiSearch } from 'react-icons/ci'
import './SearchBar.scss'
import { useSearchedProductContext } from '../Context/SearchedProductContext'

type SearchBarProps = {
	className: string
}

function SearchBar({ className }: SearchBarProps) {
	const [input, setInput] = useState<string>('')
	const { setSearchedProductName } = useSearchedProductContext()

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setSearchedProductName(input)
		}, 500)

		return () => clearTimeout(timeoutId)
	}, [input, setSearchedProductName])

	return (
		<div className={`parent ${className}`}>
			<CiSearch className="icon" />
			<input
				onChange={(e) => setInput(e.target.value)}
				value={input}
				className="input"
				type="text"
			/>
		</div>
	)
}
export default SearchBar
