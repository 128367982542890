import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import LogoutButton from '../Navigation/LogoutButton/LogoutButton'
import NavigationBar from '../Navigation/NavigationBar'
import SearchBar from '../Searchbar/SearchBar'
import useScreenWidth from '../../Hooks/useScreenWidth'
import MobileHeader from './MobileHeader'
import './Header.scss'

function Header() {
	const location = useLocation()
	const hideNavbar = ['/', '/registration', '/login'].includes(location.pathname)

	const { width } = useScreenWidth()
	const [mobileView, setMobileView] = useState(false)

	useEffect(() => {
		console.log('Width: ', width)
		if (width < 1100) setMobileView(true)
		else if (mobileView && width >= 1100) setMobileView(false)
	}, [width, mobileView])

	return (
		<div className="container">
			{!hideNavbar && mobileView ? (
				<MobileHeader />
			) : (
				!hideNavbar && (
					<>
						{location.pathname === '/wishlist' && <SearchBar className="searchbar" />}
						<NavigationBar />
						<LogoutButton className="logout-button" />
					</>
				)
			)}
		</div>
	)
}

export default Header
