import './ProductCard.scss'
import { FaCheck } from 'react-icons/fa6'
import useProductCard from './useProductCard'
import { Product } from '../../api/ProductAPI'

type ProductCardProps = {
	product: Product
	imageSrc: string
	className?: string
	setReservationModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function ProductCard({ product, imageSrc, className, setReservationModalOpen }: ProductCardProps) {
	const { reserved, handleClick } = useProductCard(setReservationModalOpen, product, imageSrc)

	return (
		<div className={`product-card ${className}`} onClick={handleClick}>
			{reserved ? (
				<div className="hover-container">
					{reserved && <FaCheck className="checkmark" size={64} />}
					{/* <p className="cancel-reservation">Reservierung aufheben?</p> */}
				</div>
			) : (
				<div className="hover-container hidden"></div>
			)}
			<img loading="lazy" src={imageSrc} alt="" className="image" />
			<div className="description-container">
				<p>{product.name}</p>
				<p id="product-price">
					{product.price}
					{product.price && '€'}
				</p>
			</div>
		</div>
	)
}

export default ProductCard
