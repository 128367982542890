import { Link, useNavigate } from 'react-router-dom'
import './StartPage.scss'
import { useEffect } from 'react'
import Cookies from 'universal-cookie'
import UserAPI from '../../api/UserAPI'
import { useAuthContext } from '../Context/AuthContext'

function StartPage() {
	const navigate = useNavigate()
	const { setUser } = useAuthContext()

	useEffect(() => {
		const verifiyCookie = async () => {
			if (!cookies.get('token')) {
				navigate('/login')
				return
			}

			const response = await UserAPI.verify()
			const { status, user } = response
			const { _id, username } = user

			setUser({ _id, username })
			status ? navigate('/wishlist') : cookies.remove('token')
		}

		const cookies = new Cookies()
		verifiyCookie()
	})

	return (
		<div id="startpage-container">
			<h1>Jennys Wunschliste</h1>
			<div className="button-container">
				<Link to={'/login'}>
					<button>
						<p className="button-title">Anmelden</p>
					</button>
				</Link>
				<Link to={'/registration'}>
					<button id="registration-button">
						<p className="button-title">Registrieren</p>
					</button>
				</Link>
			</div>
		</div>
	)
}

export default StartPage
