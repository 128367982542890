import { FC, ReactNode, useEffect, useState } from 'react'
import { AuthContext, AuthUser } from './AuthContext'

type AuthContextProviderProps = {
	children: ReactNode
}

const AuthContextProvider: FC<AuthContextProviderProps> = ({ children }) => {
	const [user, setUser] = useState<AuthUser | null>(null)

	useEffect(() => {
		const storedUser = localStorage.getItem('user')
		if (storedUser) setUser(JSON.parse(storedUser))
	}, [])

	useEffect(() => {
		if (user) localStorage.setItem('user', JSON.stringify(user))
		else localStorage.removeItem('user')
	}, [user])

	return <AuthContext.Provider value={{ user, setUser }}>{children}</AuthContext.Provider>
}

export default AuthContextProvider
