import { useNavigate } from 'react-router'
import Cookies from 'universal-cookie'
import { useAuthContext } from '../../Context/AuthContext'
import './LogoutButton.scss'

type LogoutButtonProps = {
	className?: string
}

function LogoutButton({ className }: LogoutButtonProps) {
	const navigate = useNavigate()
	const { setUser } = useAuthContext()

	const Logout = () => {
		const cookies = new Cookies()
		cookies.remove('token')
		setUser(null)
		navigate('/')
	}
	return (
		<button onClick={Logout} className={className}>
			Log out
		</button>
	)
}

export default LogoutButton
