import { createContext, useContext } from 'react'

type SearchedProductContextValue = {
	searchedProductName: string | null
	setSearchedProductName: React.Dispatch<React.SetStateAction<string | null>>
}

export const SearchedProductContext = createContext<SearchedProductContextValue | undefined>(
	undefined
)

export const useSearchedProductContext = () => {
	const searchedProductContext = useContext(SearchedProductContext)

	if (searchedProductContext === undefined) {
		throw new Error('useSearchedProductContext must be inside a SearchedProductContextProvider')
	}
	return searchedProductContext
}
