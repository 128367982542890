import { useEffect } from 'react'
import Cookies from 'universal-cookie'
import UserAPI from '../../api/UserAPI'
import Wishlist from '../Wishlist/Wishlist'
import './WishlistPage.scss'
import { useNavigate } from 'react-router'
import useVerifiyCookie from '../../Hooks/useVerifiyCookie'

type WishlistPageProps = {
	setReservationModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function WishlistPage({ setReservationModalOpen }: WishlistPageProps) {
	useVerifiyCookie()

	return (
		<>
			<h1 className="wishlist-heading">Jennys Wunschliste💝</h1>
			<Wishlist className="wishlist" setReservationModalOpen={setReservationModalOpen} />
		</>
	)
}

export default WishlistPage
