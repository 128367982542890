import './App.scss'
import { Route, Routes } from 'react-router-dom'
import ErrorPage from './Components/Screens/ErrorPage'
import ChatPage from './Components/Screens/ChatPage'
import ProfilePage from './Components/Screens/ProfilePage'
import WishlistPage from './Components/Screens/WishlistPage'
import StartPage from './Components/Screens/StartPage'
import RegistrationPage from './Components/Screens/RegistrationPage'
import LoginPage from './Components/Screens/LoginPage'
import { useState } from 'react'
import ReservationModal from './Components/ReservationModal/ReservationModal'
import ReservationContextProvider from './Components/Context/ReservationContextProvider'
import Header from './Components/Headers/Header'
import ComingSoon from './Components/Screens/ComingSoon'
import SearchedProductContextProvider from './Components/Context/SearchedProductContextProvider'

function App() {
	const [reservationModalOpen, setReservationModalOpen] = useState(false)

	return (
		<>
			<ReservationContextProvider>
				<SearchedProductContextProvider>
					{reservationModalOpen && (
						<ReservationModal setReservationModalOpen={setReservationModalOpen} />
					)}
					<Header />
					<Routes>
						<Route path="/" element={<StartPage />} />
						<Route path="/registration" element={<ComingSoon />} />
						<Route path="/login" element={<LoginPage />} />
						<Route
							path="/wishlist"
							element={<WishlistPage setReservationModalOpen={setReservationModalOpen} />}
						/>
						<Route path="/chat" element={<ComingSoon />} />
						<Route path="/profile" element={<ComingSoon />} />
						<Route path="*" element={<ErrorPage />} />
					</Routes>
				</SearchedProductContextProvider>
			</ReservationContextProvider>
		</>
	)
}

export default App
