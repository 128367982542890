import ProductCard from '../ProductCard/ProductCard'
import './Wishlist.scss'
import useWishlist from './useWishlist'

type WishlistProps = {
	className?: string
	setReservationModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function Wishlist({ className, setReservationModalOpen }: WishlistProps) {
	const { data } = useWishlist()

	return (
		<div className={`wishlist ${className}`}>
			{data.map(({ product, imageSrc }) => (
				<ProductCard
					key={product.id}
					product={product}
					imageSrc={imageSrc}
					className="product-card"
					setReservationModalOpen={setReservationModalOpen}
				/>
			))}
		</div>
	)
}

export default Wishlist
