import axios from 'axios'

const client = axios.create({ baseURL: `${process.env.REACT_APP_API_URL}/products` })

export type Product = {
	id: string
	name: string
	price?: number
	notes?: string
	links?: string[]
}

const getProducts = async () => {
	const response = await client.get('', { withCredentials: true })
	const products: Product[] = response.data.map((product: any) => ({
		id: product._id,
		name: product.name,
		price: product?.price,
		notes: product?.notes,
		links: product?.links,
	}))
	return products
}

const ProductAPI = { getProducts }
export default ProductAPI
