import { createContext, useContext } from 'react'

export type AuthUser = {
	_id: string
	username: string
}

type AuthContextValue = {
	user: AuthUser | null
	setUser: React.Dispatch<React.SetStateAction<AuthUser | null>>
}

export const AuthContext = createContext<AuthContextValue | undefined>(undefined)

export const useAuthContext = () => {
	const authContext = useContext(AuthContext)
	if (authContext === undefined) {
		throw new Error('useAuthContext must be inside a AuthContextProvider')
	}

	return authContext
}
