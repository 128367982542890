import axios from 'axios'

const client = axios.create({ baseURL: `${process.env.REACT_APP_API_URL}/reservations` })

export type Reservation = {
	_id: string
	productID: string
	userID: string
}

const getReservationForProduct = async (productID: string) => {
	try {
		const response = await client.get('/', {
			params: {
				productId: productID,
			},
			withCredentials: true,
		})
		return response
	} catch (error) {
		console.error(error)
	}
}

const reserveProduct = async (productID: string, userID: string) => {
	try {
		const response = await client.post(
			'/',
			{ productId: productID, userId: userID },
			{ withCredentials: true }
		)
		return response
	} catch (error) {
		console.error(error)
	}
}

const cancelReservationForProduct = async (productID: string, userID: string) => {
	try {
		const response = await client.post(
			'/cancel',
			{
				productId: productID,
				userId: userID,
			},
			{
				withCredentials: true,
			}
		)
		return response
	} catch (error) {
		console.error(error)
	}
}

const reservationAPI = { getReservationForProduct, reserveProduct, cancelReservationForProduct }
export default reservationAPI
