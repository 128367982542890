import { createContext, useContext } from 'react'

export type ReservedProduct = {
	id: string
	name: string
	imageSrc: string
	price?: number
	notes?: string
	links?: string[]
}

type ReservationContextValue = {
	reservedProduct: ReservedProduct | null
	setReservedProduct: React.Dispatch<React.SetStateAction<ReservedProduct | null>>
}
export const ReservationContext = createContext<ReservationContextValue | undefined>(undefined)

export const useReservationContext = () => {
	const reservationContext = useContext(ReservationContext)
	if (reservationContext === undefined) {
		throw new Error('useReservationContext must be inside a ReservationContextProvider')
	}

	return reservationContext
}
