import { useEffect, useState } from 'react'
import ProductAPI, { Product } from '../../api/ProductAPI'
import { useSearchedProductContext } from '../Context/SearchedProductContext'

type DataProduct = {
	product: Product
	imageSrc: string
}

const imageDirectory = '/Assets/ProductImages/'

function useWishlist() {
	const [data, setData] = useState<DataProduct[]>([])
	const { searchedProductName } = useSearchedProductContext()

	useEffect(() => {
		if (searchedProductName === null) return

		const fetchProducts = async () => {
			const products = await ProductAPI.getProducts()

			const mappedProducts = products.map((product) => ({
				product: product,
				imageSrc: imageDirectory + product.name + '.jpg',
			}))

			const filteredProducts = mappedProducts.filter((item) =>
				item.product.name.includes(searchedProductName)
			)
			setData(filteredProducts)
		}
		fetchProducts()
	}, [searchedProductName])

	useEffect(() => {
		const fetchProducts = async () => {
			const products = await ProductAPI.getProducts()

			const mappedProducts = products.map((product) => ({
				product: product,
				imageSrc: imageDirectory + product.name + '.jpg',
			}))

			setData(mappedProducts)
		}
		fetchProducts()
	}, [])

	return { data }
}

export default useWishlist
