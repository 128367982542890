import { useAuthContext } from '../Context/AuthContext'
import { useReservationContext } from '../Context/ReservationContext'
import './ReservationModal.scss'
import { IoClose } from 'react-icons/io5'
import reservationAPI from '../../api/ReservationAPI'

type ReservationModalProps = {
	setReservationModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const assetDirectory = '/Assets/ProductImages/'

function ReservationModal({ setReservationModalOpen }: ReservationModalProps) {
	const { reservedProduct, setReservedProduct } = useReservationContext()
	const { user } = useAuthContext()

	const handleClick = async () => {
		if (!user) {
			console.log('user is null')
			return
		}

		if (reservedProduct?.id === undefined) {
			console.log('product id is null')
			return
		}

		const response = await reservationAPI.reserveProduct(reservedProduct?.id, user._id)
		if (response?.status === 201) {
			console.log('Reserved successfully')
		}

		setReservationModalOpen(false)
		setReservedProduct(null)
	}

	return (
		<div className="modal-background">
			<div className="modal-container">
				<IoClose className="close-button" onClick={() => setReservationModalOpen(false)} />
				<img
					className="reservation-image"
					src={assetDirectory + `${reservedProduct?.name}.jpg`}
					alt="product"
				/>
				<div className="description-row">
					<p>{reservedProduct?.name}</p>
					<p>{reservedProduct?.price}€</p>
				</div>
				<div className="description-row">
					<p>{reservedProduct?.notes}</p>
				</div>
				<div className="link-container">
					{reservedProduct?.links &&
						reservedProduct?.links.map((link) => (
							<a key={link} href={link}>
								{new URL(link).hostname}
							</a>
						))}
				</div>
				<button className="reservation-button" onClick={handleClick}>
					<p>Reservieren</p>
				</button>
			</div>
		</div>
	)
}

export default ReservationModal
