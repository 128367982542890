import { useEffect } from 'react'
import Cookies from 'universal-cookie'
import UserAPI from '../api/UserAPI'
import { useNavigate } from 'react-router'

const useVerifiyCookie = () => {
	const navigate = useNavigate()

	useEffect(() => {
		const verifiyCookie = async () => {
			if (!cookies.get('token')) {
				navigate('/login')
				return
			}

			const response = await UserAPI.verify()
			const { status } = response

			if (!status) {
				navigate('/login')
				cookies.remove('token')
			}
		}

		const cookies = new Cookies()
		verifiyCookie()
	}, [navigate])
}

export default useVerifiyCookie
