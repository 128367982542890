import { useState } from 'react'
import './LoginPage.scss'
import UserAPI, { User } from '../../api/UserAPI'
import { useNavigate } from 'react-router'
import Cookies from 'universal-cookie'

function LoginPage() {
	const [name, setName] = useState('')
	const [password, setPassword] = useState('')
	const [isDataInvalid, setIsDataInvalid] = useState<boolean>(false)

	const navigate = useNavigate()

	const handleKeyDown = async (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			console.log('Enter')
			if (name !== '' && password !== '') {
				const user: User = { username: name, password: password }
				const response = await UserAPI.logIn(user)

				if (response?.status === 201) {
					const { token } = response.data
					const cookies = new Cookies()
					cookies.set('token', token, { path: '/' })

					setTimeout(() => {
						navigate('/')
					}, 1000)
				} else {
					console.log('Not correct')
					setIsDataInvalid(true)
				}
			}
		}
	}

	return (
		<div className="login-container">
			<h1>Jennys Wunschliste</h1>

			<div className="input-container">
				<p>Bitte den Nutzernamen eingeben: </p>
				<input
					type="text"
					value={name}
					onChange={(e) => setName(e.target.value)}
					className="input"
					onKeyDown={(e) => handleKeyDown(e)}
				/>
				<p>Bitte das Passwort eingeben: </p>
				<input
					type="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					className="input"
					onKeyDown={(e) => handleKeyDown(e)}
				/>
				{isDataInvalid && <p id="error-text">Nutzername oder Passwort ist nicht korrekt</p>}
			</div>
		</div>
	)
}

export default LoginPage
