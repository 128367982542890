import { useLocation } from 'react-router'
import LogoutButton from '../Navigation/LogoutButton/LogoutButton'
import NavigationBar from '../Navigation/NavigationBar'
import SearchBar from '../Searchbar/SearchBar'
import './MobileHeader.scss'

function MobileHeader() {
	const location = useLocation()

	return (
		<div className="mobile-header-container">
			<LogoutButton className="logout-button" />
			<div className="nav-container">
				{location.pathname === '/wishlist' && <SearchBar className="searchbar" />}
				<NavigationBar />
			</div>
		</div>
	)
}

export default MobileHeader
