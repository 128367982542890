import { ReactNode, useState } from 'react'
import { SearchedProductContext } from './SearchedProductContext'

type SearchedProductContextProviderProps = {
	children: ReactNode
}

function SearchedProductContextProvider({ children }: SearchedProductContextProviderProps) {
	const [searchedProductName, setSearchedProductName] = useState<string | null>(null)

	return (
		<SearchedProductContext.Provider value={{ searchedProductName, setSearchedProductName }}>
			{children}
		</SearchedProductContext.Provider>
	)
}

export default SearchedProductContextProvider
