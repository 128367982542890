import axios from 'axios'

const client = axios.create({ baseURL: `${process.env.REACT_APP_API_URL}` })

export type User = {
	username: string
	password: string
}

const isUsernameAvailable = async (name: string) => {
	try {
		const response = await client.get(`/users/names?name=${name}`)
		const isAvailable: boolean = response.data.status
		return isAvailable
	} catch (error) {
		console.error('Error: ', error)
	}
}

const signUp = async (user: User) => {
	try {
		const response = await client.post('/signup', user, {
			withCredentials: true,
		})
		return response
	} catch (error) {
		alert('An error occurred while creating the user')
	}
}

const logIn = async (user: User) => {
	try {
		const response = await client.post('/login', user, {
			withCredentials: true,
			validateStatus: function (status) {
				return status < 500
			},
		})
		return response
	} catch (error) {
		console.error(error)
	}
}

const verify = async () => {
	try {
		const response = await client.post('/', {}, { withCredentials: true })
		return response.data
	} catch (error) {
		console.error(error)
	}
}

const UserAPI = { isUsernameAvailable, signUp, logIn, verify }
export default UserAPI
